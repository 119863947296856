import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { app } from '@microsoft/teams-js'
import {
  PresenceBadgeStatus,
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
  TableCellLayout,
  useTableFeatures,
  TableColumnDefinition,
  useTableSelection,
  createTableColumn,
  Spinner,
} from '@fluentui/react-components'

import { TeamsFxContext } from '../TeamFxContextProvider'
import { fetchRecentDriveFiles } from '../../store/drive/oneDriveApiSlice'
import hubActions from '../../store/hub/actions'
import styled from 'styled-components'
import Button from '../../controls/Button'
import { login } from '../../store/auth/action'
import { colors, fontSize } from '../../theme'
import { useData } from '@microsoft/teamsfx-react'
import { useJobConfig } from '../JobConfig/JobContext'
import { isMimeTypeSupported } from '../../utils/mimeTypes'
import {
  updateReadWriteField,
  updateUserInfo,
} from '../../store/teamsAuth/teamsAuthSlice'
import ConsentBanner from '../../controls/ConsentBanner'

const TheeDotsCellLayout = styled(TableCellLayout)`
  justify-content: flex-end;
`
const CustomTableTd = styled.td`
  text-align: center;
  margin: 20px 0;
`
const NoDataText = styled.span`
  color: ${colors.textMuted};
  font-size: ${fontSize.sm};
  font-weight: 500;
`

const TableCellName = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const TableList = (props) => {
  const { ToggleMenu, isMultiple = false } = props
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const multiCarousal = useJobConfig()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const { isReadFiles } = useSelector((state) => state.teamsAuth)
  const [needConsent, setNeedConsent] = useState(false)
  const [oneDriveFiles, setOneDriveFiles] = useState([])
  const dispatch = useDispatch()
  const { recentDriveFiles, isLoading } = useSelector(
    (state) => state.recentOneDrive
  )

  useEffect(() => {
    if (recentDriveFiles.length && isReadFiles) {
      if (multiCarousal?.inputFileTypes) {
        const tempArray = recentDriveFiles.filter((e) => {
          return isMimeTypeSupported(e.name, multiCarousal?.inputFileTypes)
        })
        setOneDriveFiles(tempArray)
      } else {
        setOneDriveFiles(recentDriveFiles)
      }
    }
  }, [recentDriveFiles, multiCarousal?.inputFileTypes, isReadFiles])

  const { loading, data, error, reload } = useData(
    async () => {
      if (!teamsUserCredential) {
        throw new Error('TeamsFx SDK is not initialized.')
      }
      const userInfo = await teamsUserCredential.getUserInfo()
      if (needConsent) {
        try {
          await teamsUserCredential.login([
            'User.Read',
            'Files.Read.All',
            // 'Sites.Read.All'
          ])
          // const userInfo = await teamsUserCredential.getUserInfo()
          await dispatch(updateUserInfo({ ...userInfo, isReadFiles: true }))
          setNeedConsent(false)
        } catch (err) {
          await dispatch(updateUserInfo({ ...userInfo, isReadFiles: false }))
          setNeedConsent(true)
        }
      }
      try {
        const res = await dispatch(fetchRecentDriveFiles(teamsUserCredential))
        if (fetchRecentDriveFiles.rejected.match(res)) {
          await dispatch(updateUserInfo({ ...userInfo, isReadFiles: false }))
          setNeedConsent(true)
        } else if (fetchRecentDriveFiles.fulfilled.match(res)) {
          await dispatch(updateUserInfo({ ...userInfo, isReadFiles: true }))
          setNeedConsent(false)
        }
      } catch (error) {
        await dispatch(updateUserInfo({ ...userInfo, isReadFiles: false }))
        setNeedConsent(true)
      }
      await dispatch(updateReadWriteField())
    },
    { autoLoad: isReadFiles }
  )
  // useEffe

  // useEffect(() => {
  //   // Check if the user is already logged in
  //   const checkLoginStatus = async () => {
  //     try {
  //       const tokenResponse = await teamsUserCredential.getToken('')
  //       if (tokenResponse && tokenResponse.token) setNeedConsent(false)
  //       else setNeedConsent(true)

  //     } catch (error) {
  //       setNeedConsent(true)

  //     }
  //   }

  //   checkLoginStatus()
  // }, [])
  // useEffect(() => {
  //   ;(async () => {
  //     if (!recentDriveFiles?.length && !needConsent) {
  //       debugger
  //       dispatch(fetchRecentDriveFiles(teamsUserCredential))
  //     }
  //   })()
  // }, [needConsent])

  //   (async () => {
  //     if (!recentDriveFiles?.length && isAuthenticated && !needConsent)
  //       dispatch(fetchRecentDriveFiles(teamsUserCredential))
  //   })()
  // }, [isAuthenticated, needConsent])

  // useEffect(() => {
  //   // onedrive/GetRecentFiles?token=111
  //   const fetchApi = async () => {
  //     try {
  //       // await app.initialize()
  //       // await teamsUserCredential.login(["User.Read.All", "File.Read.All"])
  //       const token = await teamsUserCredential.getToken([
  //         'User.Read.All',
  //         'File.Read.All',
  //       ])
  //       const response = await fetch(
  //         `https://teamsappapi.pdf4me-dev.net/onedrive/GetRecentFiles?token=${token}`
  //       )
  //       console.log(response)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   // fetchApi()

  //   dispatch(hubActions.startHub())
  // }, [])
  const handleLogin = async () => {
    try {
      await dispatch(login())
      reload()
    } catch (error) {}
  }

  const columns = [
    createTableColumn({
      columnId: 'name',
    }),
    createTableColumn({
      columnId: 'size',
    }),
    createTableColumn({
      columnId: 'action',
    }),
  ]
  function formatFileSize(sizeInBytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'] // Define units
    let index = 0
    let size = sizeInBytes
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024
      index++
    }
    return `${size.toFixed(2)} ${units[index]}`
  }
  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns,
      items: oneDriveFiles || [],
    },
    [
      useTableSelection({
        selectionMode: isMultiple
          ? 'multiselect'
          : multiCarousal?.multiple
          ? 'multiselect'
          : 'single',
        selectedItems: multiCarousal?.rowsSet,
        defaultSelectedItems: multiCarousal?.rowsSet,
        onSelectionChange: (event, { selectedItems }) => {
          multiCarousal?.setRowSet(selectedItems)
          if (multiCarousal?.setSelectedRows) {
            const tempsList = []
            selectedItems.forEach((e) => tempsList.push(oneDriveFiles[e]))
            multiCarousal.setSelectedRows(tempsList)
          }
        },
      }),
    ]
  )

  const rows = getRows((row) => {
    const selected = isRowSelected(row.rowId)
    return {
      ...row,
      onClick: (e) => toggleRow(e, row.rowId),
      onKeyDown: (e) => {
        if (e.key === ' ') {
          e.preventDefault()
          toggleRow(e, row.rowId)
        }
      },
      selected,
      appearance: selected ? 'brand' : 'none',
    }
  })

  const toggleAllKeydown = React.useCallback(
    (e) => {
      if (e.key === ' ') {
        toggleAllRows(e)
        e.preventDefault()
      }
    },
    [toggleAllRows]
  )

  return (
    <Table aria-label="Table with multiselect">
      <TableHeader>
        <TableRow>
          {isMultiple ? (
            <TableSelectionCell
              checked={
                allRowsSelected ? true : someRowsSelected ? 'mixed' : false
              }
              onClick={toggleAllRows}
              onKeyDown={toggleAllKeydown}
              checkboxIndicator={{ 'aria-label': 'Select all rows ' }}
            />
          ) : (
            <TableSelectionCell
              checked={
                allRowsSelected ? true : someRowsSelected ? 'mixed' : false
              }
              checkboxIndicator={{ 'aria-label': 'Select all rows ' }}
            ></TableSelectionCell>
          )}

          <TableHeaderCell className="table__header__caption">
            Name
          </TableHeaderCell>
          {/* <TableHeaderCell>Created By</TableHeaderCell> */}
          <TableHeaderCell className="table__header__caption">
            Size
          </TableHeaderCell>
          {/* <TableHeaderCell>Action</TableHeaderCell> */}
        </TableRow>
      </TableHeader>
      <TableBody>
        {!isReadFiles ? (
          <tr>
            <CustomTableTd colSpan="4">
              <ConsentBanner onClick={reload} />
            </CustomTableTd>
          </tr>
        ) : //  !isAuthenticated ? (
        //   <tr>
        //     <CustomTableTd colSpan="4">
        //       <Button color={'primary'} onClick={handleLogin}>
        //         Sign In
        //       </Button>
        //     </CustomTableTd>
        //   </tr>
        // ) :

        isLoading ? (
          <tr>
            <CustomTableTd colSpan="4">
              <Spinner />
            </CustomTableTd>
          </tr>
        ) : !rows?.length ? (
          <TableRow>
            <CustomTableTd colSpan="4">
              <NoDataText>No data</NoDataText>
            </CustomTableTd>
          </TableRow>
        ) : (
          rows.map(({ item, selected, onClick, onKeyDown, appearance }) => (
            <TableRow
              key={item.id}
              onClick={onClick}
              onKeyDown={onKeyDown}
              aria-selected={selected}
              appearance={appearance}
              className="table__row"
            >
              <TableSelectionCell
                checked={selected}
                checkboxIndicator={{ 'aria-label': 'Select row' }}
              />
              <TableCellName>
                <TableCellLayout>{item.name}</TableCellLayout>
              </TableCellName>
              <TableCell>{formatFileSize(item.size)}</TableCell>
              {ToggleMenu && (
                <TableCell>
                  <TheeDotsCellLayout>
                    <ToggleMenu item={item} />
                  </TheeDotsCellLayout>
                </TableCell>
              )}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}
export default TableList
