import { useContext } from 'react'
import styled from 'styled-components'

import {
  layout,
  media,
  device,
  colors,
  fontSize,
} from '../../../../theme/viewer'
import Logo from './Logo'
import GoToPage from './GoToPage'
import ToolbarItem from './ToolbarItem'
import Separator from './Separator'
import IconButton from '../../../../controls/IconButton'
import Dropdown from '../../../../controls/Dropdown'
import PdfViewerContext from '../../pdfViewer/PdfViewerContext'
import { isMobile } from '../../../../utils/helper'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: ${layout.headerHeight};
  align-items: center;
  padding: 0 ${layout.gap};
  background-color: ${colors.bodyBackground};
  border-bottom: solid 2px ${colors.grayLight};
  transition: height 0.25s;
  touch-action: none;
  justify-content: space-between;

  .dropdown {
  }
  .dropdown-menu {
    right: auto;
    left: -30px;
  }

  ${media.down(device.tablet)`
    font-size: ${fontSize.sm};
    height: ${layout.mobileHeaderHeight};
    transition: height 0.25s;
  `}

  .sm-only {
    display: none;
  }

  ${media.down(device.mobile)`
    
    .sm-hide {
      display: none;
    }
    
    .sm-only {
      display: block;
    }
  `}
`

const ToolbarSection = styled.div`
  display: flex;
`

const Zoom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 67px;
  padding: 0 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  ${media.down(device.tablet)`
    min-width: 54px;
  `}
`

const PageNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  ${media.down(device.tablet)`
    min-width: 60px;
  `}
  ${media.down(device.mobile)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
    padding: 0 4px;
    border-radius: 10px;
    height: 20px;
    z-index: 10;
    background: rgba(0,0,0,0.6);
    color: ${colors.bodyBackground};
    font-size: ${fontSize.xs};
  `}
`

const BtnOutline = styled(IconButton)`
  ${media.down(device.mobile)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    left: 10px;
    padding: 0;
    border-radius: 10px;
    z-index: 10;
    background: rgba(0,0,0,0.6);
    color: ${colors.bodyBackground};
    font-size: ${fontSize.xs};
    svg {
      fill: ${colors.textContrast};
    }
  `}
`

const BtnHideAnnotationbar = styled(IconButton)`
  display: none;
  ${media.down(device.mobile)`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    width: 60px;
    padding: 0;
    z-index: 16;
    background: rgba(0,0,0,0.6);
    svg {
      fill: ${colors.textContrast};
    }
  `}
`

const BtnShowAnnotationbar = styled(IconButton)`
  display: none;
  ${media.down(device.mobile)`
    display: block;
  `}/* 
  ${media.down(device.mobile)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 60px;
    left: 10px;
    padding: 0;
    border-radius: 10px;
    z-index: 10;
    background: rgba(0,0,0,0.6);
    color: ${colors.bodyBackground};
    font-size: ${fontSize.xs};
    svg {
      fill: ${colors.textContrast};
    }
  `}
   */
`

const ToolBar = (props) => {
  const {
    hidePageNavigation,
    hidePageZoom,
    hideAnnotationBar,
    onToggleOutline,
    children,
  } = props
  const [state, actions] = useContext(PdfViewerContext)

  return (
    <Wrapper>
      <Logo />
      {state.initialized && (
        <>
          <ToolbarSection>
            {!hidePageNavigation && (
              <>
                <IconButton
                  disabled={state.currentPage <= 1}
                  className="sm-hide"
                  icon="previewsPage"
                  tooltip="Previews Page"
                  onClick={actions.previousPage}
                />
                <PageNumber>
                  {state.currentPage && (
                    <span>
                      {state.currentPage} / {state.pageCount}
                    </span>
                  )}
                </PageNumber>
                <IconButton
                  disabled={state.currentPage >= state.pageCount}
                  className="sm-hide"
                  icon="nextPage"
                  tooltip="Next Page"
                  onClick={actions.nextPage}
                />
                <Separator className="sm-hide" />
              </>
            )}
            {!hidePageZoom && (
              <>
                <ToolbarItem>
                  <IconButton
                    className="sm-hide"
                    icon="zoomIn"
                    tooltip="Zoom In"
                    onClick={actions.zoomIn}
                  />
                  <IconButton
                    className="sm-hide"
                    icon="zoomOut"
                    tooltip="Zoom Out"
                    onClick={actions.zoomOut}
                  />
                  <Dropdown
                    className="sm-hide"
                    items={[
                      {
                        text: 'Fit to page',
                        icon: 'fitPage',
                        action: () => {
                          actions.setFitMode(2)
                        },
                      },
                      {
                        text: 'Fit to width',
                        icon: 'fitWidth',
                        action: () => {
                          actions.setFitMode(1)
                        },
                      },
                      {
                        text: '50%',
                        action: () => {
                          actions.setZoom(0.5)
                        },
                      },
                      {
                        text: '100%',
                        action: () => {
                          actions.setZoom(1)
                        },
                      },
                      {
                        text: '150%',
                        action: () => {
                          actions.setZoom(1.5)
                        },
                      },
                      {
                        text: '200%',
                        action: () => {
                          actions.setZoom(2)
                        },
                      },
                    ]}
                  >
                    <Zoom>
                      {state.zoom && (
                        <span>{Math.floor(state.zoom * 100)} %</span>
                      )}
                    </Zoom>
                  </Dropdown>
                </ToolbarItem>
              </>
            )}

            {children}
          </ToolbarSection>

          <ToolbarSection>
            {!hideAnnotationBar && (
              <>
                {!state.showAnnotationbar && (
                  <BtnShowAnnotationbar
                    icon="editPdf"
                    tooltip="Show Annotation Toolbar"
                    onClick={actions.toggleAnnotationBar}
                  />
                )}
                {state.showAnnotationbar && (
                  <BtnHideAnnotationbar
                    tooltip="Close Annotation Toolbar"
                    icon="previewsPage"
                    onClick={actions.toggleAnnotationBar}
                  />
                )}
              </>
            )}

            {state.documentOutline && (
              <BtnOutline
                icon="outline"
                tooltip="Document Outline"
                onClick={onToggleOutline}
              />
            )}

            {/* features && features.length > 0 && (
            <>
              <IconButton
                disabled={disabled}
                icon="editPdf"
                onClick={onToggleAnnotationMenu}
              />
            </>
          )*/}
            {/* {!isMobile() && (
              <IconButton
                disabled={false}
                icon="print"
                tooltip="Print"
                onClick={actions.print}
              />
            )} */}

            <IconButton
              disabled={!state.currentPage}
              icon="download"
              tooltip="Download"
              onClick={actions.save}
            />
            <IconButton
              disabled={!state.currentPage}
              icon="upload"
              tooltip="Upload One drive"
              onClick={actions.saveToOneDrive}
            />
            {/* <IconButton
              disabled={!state.currentPage}
              icon="dropbox"
              tooltip="Save to Dropbox"
              onClick={actions.saveToDropbox}
            />
            <IconButton
              disabled={!state.currentPage}
              icon="googleDrive"
              tooltip="Save to Google Drive"
              onClick={actions.saveToGoogleDrive}
            />
            <IconButton
              disabled={!state.currentPage}
              icon="oneDrive"
              tooltip="Save to OneDrive"
              onClick={actions.saveToOneDrive}
            /> */}
          </ToolbarSection>
        </>
      )}
    </Wrapper>
  )
}

export default ToolBar
