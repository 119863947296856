import React, { useState } from 'react'
import { Tab, TabList } from '@fluentui/react-components'

import MyComputer from '../MyComputer'
import Table from '../Table'
import { useJobConfig } from '../JobConfig/JobContext'
import { CustomTab, PopupBody, PopupWrapper } from './styles'
import { PopupCat } from '../../utils/enums'

const ActionPopup = ({ tab, updateTab }) => {
  const { accept, tool, onFileSelect } = useJobConfig()

  const handleTabChange = (event, data) => {
    updateTab(data.value)
  }
  const renderBody = (tab) => {
    switch (tab) {
      case PopupCat.RECENT:
        return <Table />
      case PopupCat.MY_COMPUTER:
        return (
          <MyComputer
            tab={tab}
            onFileSelect={onFileSelect}
            tool={tool}
            accept={accept}
          />
        )
      default:
        return <Table />
    }
  }
  return (
    <>
      <PopupWrapper>
        <TabList
          defaultSelectedValue={PopupCat.RECENT}
          onTabSelect={handleTabChange}
          appearance="transparent"
        >
          {Object.keys(PopupCat).map((e, i) => (
            <CustomTab key={e + '_' + i} value={PopupCat[e]}>
              {PopupCat[e]}
            </CustomTab>
          ))}
        </TabList>
        <PopupBody>{renderBody(tab)}</PopupBody>
      </PopupWrapper>
    </>
  )
}

export default ActionPopup
