export const errorHandler = (err) => (dispatch) => {
  let { message } = err
  switch (err.code) {
    case 401: // auth failed
      if (
        err.message === 'invalid signature' ||
        err.message === 'Not authenticated' ||
        err.message === 'jwt expired'
      ) {
        message = 'Your session is expired. Please re-login'
        localStorage.removeItem('p4m')
        // dispatch({
        //   type: "LOGOUT",
        // });
      } else if (
        err.message === 'SMS is not supported by landline phone number'
      ) {
        message =
          'SMS is not supported by landline phone number, please contact administrator.'
      } else if (
        err.message === 'Email ID or password is incorrect' ||
        err.message === 'Invalid Login' ||
        err.message === 'Invalid login'
      ) {
        message = 'Email ID or password is incorrect'
      } else if (err.message === 'OTP wrong.') {
        message = 'You have entered a wrong code.'
      } else if (err.message === 'OTP expired.') {
        message = 'Your code is expired. Please re-login'
      } else if (err.message === 'Too many requests') {
        message = 'Too many requests'
      }
      break
    case 400: // validation error
      if (err.errors && err.errors[0]) {
        const error = err.errors[0]
        if (
          error.message === 'mobile must be unique' ||
          error.message === 'users.mobile must be unique'
        ) {
          message = 'Mobile number already exists.'
        }
        if (
          error.message === 'users.email must be unique' ||
          error.message === 'email must be unique'
        ) {
          message = 'Email address already exists.'
        }
      }
      break
    case 403: // forbidden
      message = 'You do not have permission to access this resource.'
      break
    case 404: // not found
      message = 'Oops... something went wrong.'
      break
    case 408: // timeout
      message = 'Request timed out, please try again.'
      break
    default:
      message = err?.message || 'An unknown error occurred.'
      break
  }
  if (message === `Unexpected token 'A', "Azure.Iden"... is not valid JSON`) {
    dispatch({
      type: 'SHOW_ERROR',
      data: {
        ...err,
        message: 'Permission denied: necessary permissions not granted',
        error: { message },
      },
    })
    return
  }
  dispatch({
    type: 'SHOW_ERROR',
    data: { ...err, message },
  })
}

export const successHandler = (message) => (dispatch) => {
  dispatch({
    type: 'SHOW_SUCCESS',
    data: {
      message,
    },
  })
}

export const removeError = () => (dispatch) => {
  return dispatch({
    type: 'REMOVE_MESSAGE',
    data: {},
  })
}
