import React, { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ConfigWrapper,
  FileItem,
  PopupBody,
  PreviewWrapper,
  Thumbnail,
} from './styles'
import {
  FileProps,
  File,
  FileName,
  FileDesc,
  Row,
  FileList,
  FileActions,
} from '../File/components'
import ProgressBar from '../../controls/ProgressBar'
import { formatFileSize } from '../../utils/helper'
import ActionConfigGenerator from './ActionConfigGenerator'
import { useJobConfig } from '../JobConfig/JobContext'
import FileIcon from '../File/FileIcon'
import jobConfig from '../../configuration/jobConfig'
import translation from '../../assets/translation'
import Button from '../../controls/Button'
import trashIcon from '../../controls/icons/trash'
import { deleteNotification } from '../../store/notification/action'
import PdfPasswordRequired from '../../components/File/PdfPasswordRequired'
import FileSkeletonLoader from '../File/FileListSkeletonLoader'
const RenderItem = memo((props) => {
  const t = translation('shared')
  const { file, onDeleteFile, onCancelUpload, i, isShowDelete } = props
  if (file.status === 'error') {
    switch (file.error) {
      // case 'UploadFailed':
      //   return <FileUploadError {...file} onDeleteFile={onDeleteFile} />
      case 'PasswordRequired':
        return (
          <PdfPasswordRequired
            {...file}
            isShowDelete={isShowDelete}
            onDeleteFile={onDeleteFile}
          />
        )
      default:
        return
    }
  }
  return (
    <FileItem>
      <File key={file?.docIdExt + i}>
        <Thumbnail>
          <FileIcon
            name={file?.fileName}
            thumbnail={file?.docMetadata?.thumbnail}
            inProgress={file?.status !== 'finished'}
          />
        </Thumbnail>
        <FileDesc>
          <FileName>{(file && file?.fileName) || ''}</FileName>
          {file?.status !== 'finished' && (
            <ProgressBar progress={file?.progress} />
          )}
          {file?.status === 'finished' && (
            <Row>
              <FileProps>
                <span>
                  {t('lblFileSize')}: {formatFileSize(file?.docMetadata?.size)}
                </span>
                <span>
                  {t('lblPageCount')}: {file?.docMetadata?.pageCount}
                </span>
              </FileProps>
              {isShowDelete && (
                <FileActions>
                  <Button
                    type="button"
                    size="small"
                    startIcon={trashIcon}
                    onClick={onDeleteFile}
                  >
                    {t('btnDelete')}
                  </Button>
                </FileActions>
              )}
            </Row>
          )}
        </FileDesc>
      </File>
    </FileItem>
  )
})
const StartJobPopup = () => {
  const { tool, actionConfig, docIdExt, updateActionConfig, fileUploadOpen } =
    useJobConfig()
  const dispatch = useDispatch()

  const inputFiles = useSelector((state) =>
    state.notification.filter(
      (e) => !e?.isNotification && e.toolName === tool?.toolName
    )
  )
  const [isPristine, setIsPristine] = useState(true)
  useEffect(() => {
    const configs =
      tool && jobConfig[tool.toolName] && jobConfig[tool.toolName].jobConfig
        ? jobConfig[tool.toolName].jobConfig
        : {}

    let _config = null
    Object.keys(configs).map((value, index, array) => {
      console.log('value, index, array', value, index, array)
      _config = configs[value]
    })
    updateActionConfig(_config)
    // setActionConfig(_config)
  }, [])

  const onChange = (e) => {
    let _config = { ...actionConfig }
    if (e?.target?.name) {
      if (e.target.name === 'ocr')
        _config = { ..._config, [e.target.name]: e.target.checked }
      else _config = { ..._config, [e.target.name]: e.target.value }
    }

    // setActionConfig({ ..._config })
    updateActionConfig(_config)
    setIsPristine(false)
  }

  const _inputFiles = Array.isArray(inputFiles) ? inputFiles : [inputFiles]
  console.log('inputFiles', inputFiles)

  return (
    <PopupBody>
      <PreviewWrapper>
        <FileList>
          {!_inputFiles || !_inputFiles.length ? (
            <FileSkeletonLoader />
          ) : (
            _inputFiles.map((file, i) => (
              <RenderItem
                file={file}
                i={i}
                onDeleteFile={() => dispatch(deleteNotification(file.docIdExt))}
                isShowDelete={_inputFiles.length > 1}
              />
            ))
          )}
        </FileList>

        <ConfigWrapper>
          {/* <AddFilesWrapper>
            <AddFilesInfo>
              <strong>{maxFiles - (inputFiles.length || 0)}</strong>{' '}
              {t('addFileInfo1')} <strong>{maxFiles}</strong>{' '}
              {t('addFileInfo2')}
            </AddFilesInfo>
            <Root className="btn btn--secondary" endIcon={AngleDownIcon}>
              <SelectFileSource
                alignDropdown="right"
                label={t('btnAddFile')}
                color="secondary"
              />
            </Root>
          </AddFilesWrapper> */}
          <ActionConfigGenerator
            toolName={tool.toolName}
            inputFiles={_inputFiles}
            form={actionConfig}
            disabled={false}
            isPristine={isPristine}
            onChange={onChange}
          />
        </ConfigWrapper>
      </PreviewWrapper>
    </PopupBody>
  )
}

export default StartJobPopup
