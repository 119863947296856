import React, { createContext, useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icons from '../../assets/Icons'
import { MessageType } from '../../utils/enums'
import { removeError } from '../../store/error/action'

// Create the Message Context
const MessageContext = createContext()

// Create the MessageProvider component
const MessageProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState(null)
  const [type, setType] = useState(null)

  const showMessage = (msg, msgType) => {
    setMessage(msg)
    setType(msgType)
    setTimeout(() => {
      setMessage(null)
      setType(null)
      dispatch(removeError())
    }, 5000)
  }

  return (
    <MessageContext.Provider value={{ message, type, showMessage }}>
      {children}
    </MessageContext.Provider>
  )
}

// Custom hook to use the Message context
export const useMessage = () => useContext(MessageContext)

// Create the MessageToast component
const MessageToast = () => {
  const { message, type, showMessage } = useMessage()
  const error = useSelector((state) => state?.toastMessage || {})
  useEffect(() => {
    if (error && typeof error === 'object' && Object.keys(error).length) {
      showMessage(error?.message, error?.type)
    }
  }, [error])
  const handleClose = () => {
    showMessage(null, null)
  }

  if (type === MessageType.Success && message) {
    return (
      <div className="success_box alertbox_encounter">
        <div className="msg__icon">
          <Icons toolName={'chromeSuccess'} />
        </div>

        {message}
        <button className="clsbtn__cross" onClick={handleClose}>
          <Icons toolName={'chromeClose'} />
        </button>
      </div>
    )
  }

  if (type === MessageType.Error && message) {
    return (
      <div className="error_box alertbox_encounter" style={{ display: 'flex' }}>
        <div className="msg__icon">
          <Icons toolName={'circleAlert'} />
        </div>
        <div className="pswrdblock">
          {typeof message !== 'string' && message[0] && message[0].length > 0
            ? message[0]
                .split('*')
                .map((c, i) => <p key={i}>{i > 0 ? `* ${c}` : c}</p>)
            : message}
        </div>
        <button className="clsbtn__cross" onClick={handleClose}>
          <Icons toolName={'chromeClose'} />
        </button>
      </div>
    )
  }

  return <></>
}

// Create the MessageBox component that uses the MessageProvider
export const withMessageBox = (WrappedComponent) => {
  return (props) => (
    <MessageProvider>
      <MessageToast />
      <WrappedComponent {...props} />
    </MessageProvider>
  )
}
