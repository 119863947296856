import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import { updateReadWriteField } from '../../store/teamsAuth/teamsAuthSlice'
import FormSection from '../FormSection'
import FormRow from '../FormRow'
import ButtonRow from '../ButtonRow'
import Button from '../Button'
import Popup from '../Popup'

export const CustomButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`
const PopupWrapper = styled(FormSection)`
  padding: 10px;
`
const NeeConsentPopup = ({ onClose }) => {
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const dispatch = useDispatch()
  const getFileReadAccess = async () => {
    try {
      await teamsUserCredential.login(['Files.ReadWrite.All'])
      dispatch(updateReadWriteField({ isReadWrite: true }))
      // setNeedConsent(false)
      onClose()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Popup size="md" onClose={onClose}>
      <PopupWrapper>
        <FormSection>
          <FormRow>
            We need access to your files to read, edit, and manage them within
            the app.
          </FormRow>
          <ButtonRow center noWrap>
            <CustomButton color={'secondary'} onClick={getFileReadAccess}>
              Authorize
            </CustomButton>
          </ButtonRow>
        </FormSection>
      </PopupWrapper>
    </Popup>
  )
}

export default NeeConsentPopup
