import React from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../theme'

// Shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 96px;
  background-color: ${colors.grayLight};
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  padding: 8px;
`
const ImageWrapper = styled.div`
  width: 116px;
  height: 96px;
`
const ImagePlaceholder = styled.div`
  width: 96px;
  height: 96px;
  background-color: ${colors.gray};
  border-radius: 4px;
  margin-right: 16px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(
    to right,
    ${colors.gray} 0%,
    ${colors.grayLight} 50%,
    ${colors.gray} 100%
  );
  background-size: 400px 100%;
`

const TextPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 299px;
  height: 96px;
  padding: 8px 16px 8px 16px;
`

const FilenamePlaceholder = styled.div`
  width: 80%;
  height: 20px;
  background-color: ${colors.gray};
  border-radius: 4px;
  margin-bottom: 8px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(
    to right,
    ${colors.gray} 0%,
    ${colors.grayLight} 50%,
    ${colors.gray} 100%
  );
  background-size: 400px 100%;
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const SizePlaceholder = styled.div`
  width: 40%;
  height: 16px;
  background-color: ${colors.gray};
  border-radius: 4px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(
    to right,
    ${colors.gray} 0%,
    ${colors.grayLight} 50%,
    ${colors.gray} 100%
  );
  background-size: 400px 100%;
`

const PipeSeparator = styled.div`
  margin: 0 8px;
  font-weight: bold;
  color: ${colors.gray};
`

const PageCountPlaceholder = styled.div`
  width: 40%;
  height: 16px;
  background-color: ${colors.gray};
  border-radius: 4px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(
    to right,
    ${colors.gray} 0%,
    ${colors.grayLight} 50%,
    ${colors.gray} 100%
  );
  background-size: 400px 100%;
`

const FileSkeletonLoader = () => {
  return (
    <LoaderContainer>
      {/* Left Side Image Placeholder */}
      <ImageWrapper>
        <ImagePlaceholder />
      </ImageWrapper>

      {/* Right Side Text Placeholders */}
      <TextPlaceholder>
        {/* Top File Name */}
        <FilenamePlaceholder />

        {/* Bottom Info - File Size and Page Count */}
        <InfoContainer>
          <SizePlaceholder />
          <PipeSeparator>|</PipeSeparator>
          <PageCountPlaceholder />
        </InfoContainer>
      </TextPlaceholder>
    </LoaderContainer>
  )
}

export default FileSkeletonLoader
