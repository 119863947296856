export const fontSize = {
  normal: '15px',
  sm: '14px',
  xs: '12px',
  lg: '17px',
  xl: '18px',
  h1: '40px',
  h2: '26px',
  h3: '22px',
  h4: '18px',
  h5: '15px',
  display1: '56px',
  display2: '28px',
  display3: '22px',
}
export const lineHeight = {
  normal: '1.375rem', // 22px / 16 = 1.375rem
  sm: '1.25rem', // 20px / 16 = 1.25rem
  xs: '1.125rem', // 18px / 16 = 1.125rem
  lg: '1.5rem', // 24px / 16 = 1.5rem
  xl: '1.625rem', // 26px / 16 = 1.625rem
  h1: '3rem', // 48px / 16 = 3rem
  h2: '2rem', // 32px / 16 = 2rem
  h3: '1.75rem', // 28px / 16 = 1.75rem
  h4: '1.5rem', // 24px / 16 = 1.5rem
  h5: '1.375rem', // 22px / 16 = 1.375rem
  display1: '4rem', // 64px / 16 = 4rem
  display2: '2.125rem', // 34px / 16 = 2.125rem
  display3: '1.75rem', // 28px / 16 = 1.75rem
}
