export const ToolName = {
  EXTRACT_RESOURCES: 'extractResources',
  PDF_TO_EPUB: 'pdfToEpub',
  PDF_TO_EXCEL: 'pdfToExcel',
  PDF_TO_IMAGE: 'pdfToImage',
  PDF_TO_POWERPOINT: 'pdfToPowerpoint',
  PDF_TO_WORD: 'pdfToWord',
  EXCEL_TO_PDF: 'excelToPdf',
  IMAGE_TO_PDF: 'imageToPdf',
  PDF_CONVERTER: 'pdfConverter',
  POWERPOINT_TO_PDF: 'powerpointToPdf',
  WORD_TO_PDF: 'wordToPdf',
  ADD_BARCODE: 'addBarcode',
  ADD_WATERMARK: 'addWatermark',
  MERGE_PDF: 'mergePdf',
  ORGANIZE_PAGES: 'organizePages',
  PDF_EDITOR: 'pdfEditor',
  PDF_METADATA: 'pdfMetadata',
  SIGN_PDF: 'signPdf',
  FLATTEN_PDF: 'flattenPdf',
  REDACT_PDF: 'redactPdf',
  MERGE_PDF_OVERLAY: 'mergePdfOverlay',
  SPLIT_PDF: 'splitPdf',
  COMPRESS_PDF: 'compressPdf',
  OPTIMIZE_FOR_WEB: 'optimizeForWeb',
  PDF_OCR: 'pdfOcr',
  PREPARE_FOR_PRINT: 'prepareForPrint',
  REPAIR_PDF: 'repairPdf',
  PROTECT_PDF: 'protectPdf',
  UNLOCK_PDF: 'unlockPdf',
  JOB_HISTORY: 'jobHistory',
}

export const PopupScreen = {
  Action: 'Continue',
  StartJob: 'Start Job',
}

export const MessageType = {
  Success: 'Success',
  Error: 'Error',
}

export const HeaderActiveLink = {
  '/home': 'Home',
  '/document': 'Document',
  '/viewer/': 'Edit PDF',
  '/sign-viewer/': 'Sign PDF',
}
export const PopupCat = {
  RECENT: 'Recent',
  MY_COMPUTER: 'My Computer',
}

export const LoadingStates = {
  IDLE: 'idle',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
}
