import { combineReducers } from 'redux'

import { configureStore } from './store'
import driveReducer from './drive/oneDriveApiSlice'
// import getAuthTokenSlice from './pdf4meAuth/getAuthTokenSlice'
import { apiSlice } from './api/apiSlice'
import viewerSliceReducer from './viewer/downloadApiSlice'
import hubReducer from './hub/reducer'
import notificationReducer from './notification/reducer'
import { hubMiddleware } from './hub/hubMiddleware'
import toastReducer from './error/reducer'
import authReducer from './auth/reducer'
import oneDrivePickerReducer from './drive/Slice/oneDrivePickerSlice'
import barcodeReducer from './barcode/reducer'
import thumbnailsReducer from './thumbnails/reducer'
import stampReducer from './stamp/reducer'
import teamsAuthReducer from './teamsAuth/teamsAuthSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  // app: getAuthTokenSlice,
  oneDrive: oneDrivePickerReducer,
  recentOneDrive: driveReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  viewer: viewerSliceReducer,
  hub: hubReducer,
  barcode: barcodeReducer,
  stamp: stampReducer,
  thumbnails: thumbnailsReducer,
  notification: notificationReducer,
  toastMessage: toastReducer,
  auth: authReducer,
  teamsAuth: teamsAuthReducer,
})
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Choose which reducers to persist
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore(persistedReducer, hubMiddleware)

export default store
export const persistor = persistStore(store)
