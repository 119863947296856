import React from 'react'
import styled from 'styled-components'

import Icons from '../../assets/Icons'
import { colors, device, media } from '../../theme'

export const DriveButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background);
  padding: 0 24px;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 500;
  transition: box-shadow 0.2s;
  border: 1px solid ${colors.secondary} !important;
  color: ${colors.secondary};
  min-height: 32px;
  height: auto;
  min-width: 72px;
  border-radius: 16px;
  &:hover {
    border: 1px solid ${colors.secondaryLight} !important;
  }
  & > input {
    position: absolute;
    opacity: 0;
    width: 85px;

    // z-index: -1;
  }
  & > div {
    width: 21px;
    height: 21px;
  }
  label {
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  ${media.down(device.tablet)`
    min-width: unset;
    padding: unset; 
    min-height: unset;
    border: none !important;
    label {
    display: none;
    }
    .btn__startIcon{
     margin-right: 0; 
     margin-left: 0;
    }
    #DriveButton{
    width: 21px;
    height: 21px;
    padding: unset;
    }
    `}
`

const UploadIcon = ({
  onChange,
  className,
  multiple,
  accept,
  type = 'file',
  onClick,
  disabled,
  label,
  iconName
}) => {
  const classes = ['btn', 'btn--link ']
  if (className) classes.push(className)
  return (
    <DriveButton>
      {/* <Icon icon={Upload} /> */}
      <Icons toolName={iconName} className={'btn__startIcon'} />
      <label htmlFor="DriveButton">{label}</label>
      <input
        id="DriveButton"
        className={classes.join(' ')}
        type={type}
        onChange={onChange}
        onClick={onClick}
        multiple={multiple}
        accept={accept}
      />
    </DriveButton>
  )
}

export default UploadIcon
