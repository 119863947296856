import { createSlice } from '@reduxjs/toolkit'
import { startHub, stopHub } from './hubMiddleware'

const initialState = {
  connection: null,
  connectionId: null,
  status: 'idle',
  error: null,
  docIds: [],
}

const hubSlice = createSlice({
  name: 'hub',
  initialState,
  reducers: {
    setConnecting: (state) => {
      state.status = 'connecting'
      state.error = null
    },
    setConnected: (state, action) => {
      state.status = 'connected'
      state.connection = action.payload.connection
      state.connectionId = action.payload.connectionId
      state.error = null
    },
    setError: (state, action) => {
      state.status = 'error'
      state.error = action.payload
    },
    setClosed: (state) => {
      state.status = 'closed'
      state.connection = null
      state.connectionId = null
      state.error = null
    },
    setClosing: (state) => {
      state.status = 'closing'
    },
    setJobId: (state, action) => {
      state.docIds.push(action.payload)
    },
    removeJobId: (state, action) => {
      state.docIds = state.docIds.filter((e) => e != action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startHub.pending, (state) => {
        state.status = 'connecting'
      })
      .addCase(startHub.fulfilled, (state, action) => {
        state.status = 'connected'
        state.connection = action.payload.connection
        state.connectionId = action.payload.connectionId
      })
      .addCase(startHub.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload
      })
      .addCase(stopHub.pending, (state) => {
        state.status = 'closing'
      })
      .addCase(stopHub.fulfilled, (state) => {
        state.status = 'closed'
        state.connection = null
        state.connectionId = null
      })
      .addCase(stopHub.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload
      })
  },
})

export const {
  setConnecting,
  setConnected,
  setError,
  setClosed,
  setClosing,
  removeJobId,
  setJobId,
} = hubSlice.actions

export default hubSlice.reducer
