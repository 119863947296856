import styled from 'styled-components'

import Button from '../../controls/Button'
import { colors, device, fontSize, lineHeight, media } from '../../theme'

export const BannerButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`
export const BannerTitle = styled.h1`
  display: inline-block;
  flex-shrink: 1;
  color: var(--text)
  margin: '0 0 4px';
`

export const ThreeDotsButton = styled(Button)`
  background: 'transparent',
    border: 'none',
    '&:hover:active': {
      background: 'transparent',
      border: 'none',
    },
`
export const BannerSubTitle = styled.h1`
  margin: 0;
  color: var(--text);
`
export const HomeTabContent = styled.div`
  overflow-x: hidden;
  /* WebKit browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.bgLighter};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary};
    border-radius: 4px;
    border: 3px solid ${colors.bgLighter};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primaryDark};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.secondary} ${colors.bgLighter};
`

export const UploadBtn = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background);
  border: solid 1px rgba(22, 33, 57, 0.2);
  border-radius: 6px;
  height: 44px;
  padding: 0 24px;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: box-shadow 0.2s;
  border: none;
  & > input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
`
export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  ${media.down(device.tablet)`
    position: absolute;
    top: 62px;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 74vh;
  `}

  ${media.down(device.mobile)`
    top: 52px;
  `}
`
