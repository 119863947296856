import { ToolName } from './enums'
import jobConfig from '../configuration/jobConfig'
import toolList from '../assets/data/toolList.json'

export const getActionConfig = (toolName) => {
  switch (toolName) {
    case ToolName.PDF_CONVERTER:
    case ToolName.POWERPOINT_TO_PDF:
    case ToolName.EXCEL_TO_PDF:
    case ToolName.WORD_TO_PDF:
    case ToolName.IMAGE_TO_PDF:
    case ToolName.MERGE_PDF:
      return [
        {
          actionType: 'convertToPdf',
          parameters: [],
        },
      ]
    case ToolName.COMPRESS_PDF:
      return [
        {
          actionType: 'optimize',
          parameters: [{ name: 'profile', value: 'compress' }],
        },
      ]
    case ToolName.PDF_TO_WORD:
      return [
        {
          actionType: 'convertFromPdf',
          parameters: [
            {
              name: 'outputFormat',
              value: 'docx',
            },
            {
              name: 'quality',
              value: 'draft',
            },
          ],
        },
      ]
    case ToolName.PDF_TO_POWERPOINT:
      return [
        {
          actionType: 'convertFromPdf',
          parameters: [
            {
              name: 'outputFormat',
              value: 'pptx',
            },
            {
              name: 'quality',
              value: 'draft',
            },
          ],
        },
      ]
    case ToolName.PDF_TO_EXCEL:
      return [
        {
          actionType: 'convertFromPdf',
          parameters: [
            {
              name: 'outputFormat',
              value: 'excel',
            },
            {
              name: 'quality',
              value: 'draft',
            },
          ],
        },
      ]
    case ToolName.PDF_TO_EPUB:
      return [
        {
          actionType: 'convertFromPdf',
          parameters: [
            {
              name: 'outputFormat',
              value: 'epub',
            },
            {
              name: 'quality',
              value: 'draft',
            },
            {
              name: 'title',
              value: null,
            },
            {
              name: 'author',
              value: null,
            },
            {
              name: 'device',
              value: 'default',
            },
            {
              name: 'firstPageCover',
              value: 'true',
            },
            {
              name: 'coverType',
              value: 'firstPage',
            },
            {
              name: 'texture',
              value: '',
            },
            {
              name: 'thumbnailBlobRef',
              value: '',
            },
          ],
        },
      ]
    case ToolName.PDF_TO_IMAGE:
      return [
        {
          actionType: 'image',
          parameters: [
            {
              name: 'imageFormat',
              value: 'jpg',
            },
            {
              name: 'width',
              value: 700,
            },
            {
              name: 'pageSequence',
              value: 'all',
            },
          ],
        },
      ]

    case ToolName.ADD_BARCODE:
      return [
        {
          actionType: 'addBarcode',
          parameters: [
            {
              name: 'BarcodeType',
              value: '',
            },
            {
              name: 'Text',
              value: '',
            },
            {
              name: 'Width',
              value: '',
            },
            {
              name: 'Height',
              value: '',
            },
            {
              name: 'PageSequence',
              value: '',
            },
            {
              name: 'AlignX',
              value: '',
            },
            {
              name: 'AlignY',
              value: '',
            },
            {
              name: 'Rotate',
              value: '',
            },
            {
              name: 'MarginX',
              value: '',
            },
            {
              name: 'MarginY',
              value: '',
            },
            {
              name: 'BarcodeColor',
              value: '',
            },
            {
              name: 'BackgroundColor',
              value: '',
            },
            {
              name: 'HideText',
              value: 'true',
            },
          ],
        },
      ]

    case ToolName.ADD_WATERMARK: {
      return [
        {
          actionType: 'stamp',
          parameters: [],
        },
      ]
    }
    case ToolName.ORGANIZE_PAGES: {
      return [
        {
          actionType: 'produce',
          parameters: [],
        },
      ]
    }
    case ToolName.EXTRACT_RESOURCES: {
      return [
        {
          actionType: 'extractResources',
          parameters: [
            {
              name: 'extract',
              value: '',
            },
          ],
        },
      ]
    }
    case ToolName.PDF_METADATA: {
      return [
        {
          actionType: 'editMetadata',
          parameters: [
            {
              name: 'title',
              value: 'xfgfgdg',
            },
            {
              name: 'subject',
              value: '',
            },
            {
              name: 'author',
              value: '',
            },
            {
              name: 'producer',
              value: 'PDFium',
            },
            {
              name: 'creator',
              value: 'PDFium',
            },
            {
              name: 'keywords',
              value: 'kghuihu',
            },
            {
              name: 'permissions',
              value:
                'assemble,copy,fillForms,annotate,modify,print,supportDisabilities',
            },
            {
              name: 'securityType',
            },
          ],
        },
      ]
    }
    default:
      return [
        {
          actionType: 'convertToPdf',
          parameters: [],
        },
      ]
  }
}

export const getJobConfig = (toolName) => {
  const config = { ...jobConfig.defaults, ...jobConfig[toolName] }
  return config
}

export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'] // Define units
    let index = 0
    let size = sizeInBytes
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024
      index++
    }
    return `${size.toFixed(2)} ${units[index]}`
  }
  return 0
}
export const isValidURL = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}
export const redirectToViewer = (navigate, file, toolName, mode = 'viewer') => {
  const URL = `/${mode}/?fileurl=${encodeURIComponent(
    file
  )}&name=${encodeURIComponent(toolName)}`
  typeof navigate === 'function' && navigate(URL)
}

export const getFileFromURL = async (url, name) => {
  try {
    if (isValidURL(url)) {
      const resFile = await fetch(url)
      const blob = await resFile.blob()
      const file = new File([blob], name, { type: blob.type })
      return file
    }
  } catch (error) {
    throw new Error(error)
  }
}

export const getToolByName = (toolName) => {
  return toolList.find((e) => e.toolName === toolName) || {}
}
export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export const fetchToken = async (teamsUserCredential) => {
  const tokenResponse = await teamsUserCredential?.getToken('')
  if (!tokenResponse) throw new Error('Failed to retrieve token')
  return tokenResponse.token
}

// export const handleAsyncError = (error, dispatch) => {
//   const errorMessage = error.message || 'An error occurred';
//   dispatch(errorHandler({ message: errorMessage }));
//   return rejectWithValue(errorMessage);
// };
