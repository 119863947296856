import styled from 'styled-components'

import { icons } from './icons'

const I = styled.i`
  padding: 0;
  margin: 0;
  width: 26px;
  height: 18px;
  text-align: center;
  display: inline-block;
  svg {
    width: 21px;
    height: 17px;
  }
`

const Icon = ({ className, icon }) => {
  return (
    <>
      {icon && icons[icon] && (
        <I className={className}>
          <svg viewBox={`0 0 ${icons[icon].w} ${icons[icon].h}`}>
            <path fill="currentColor" d={icons[icon].p} />
          </svg>
        </I>
      )}
    </>
  )
}

export default Icon
