import React from 'react'
import styled from 'styled-components'
import Popup from '../Popup'
import { colors, device, fontSize, media } from '../../theme'
import Button from '../Button'
import IconButton from '../IconButton'

const AuthPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 33%;
  align-items: center;
  justify-content: center;
  row-gap: 18px;
`
const AuthPopupHeader = styled.h2`
  font-size: ${fontSize.h1};
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  color: #0b1324;
  ${media.down(device.tablet)`
   font-size: ${fontSize.h2};
  `}
`
const AuthPopupText = styled.div`
  font-size: ${fontSize.sm};
  color: ${colors.gray};
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 10px 0 1.5rem;
`
const Content = styled.div`
  padding: 0 1.5rem;
  flex: 1 1;
  // overflow: auto;
  min-height: 200px;
  padding: 1rem;
  // height: 50%;
  ${media.down(device.tablet)`
    position: absolute;
    top: 62px;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 100%;
  `}

  ${media.down(device.mobile)`
    top: 52px;
  `}
`
const AuthPopup = ({ onClose, SignIn }) => {
  return (
    <Popup onClose={onClose} isCustom>
      <Content>
        <AuthPopupWrapper>
          {onClose && (
            <Header>
              <IconButton onClick={onClose} icon="close" />
            </Header>
          )}
          <AuthPopupHeader>Sign in to PDF4me</AuthPopupHeader>
          <AuthPopupText>Please login to your account.</AuthPopupText>
          <Button onClick={SignIn} color={'primary'}>
            Sign In
          </Button>
        </AuthPopupWrapper>
      </Content>
    </Popup>
  )
}

export default AuthPopup
