import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { keyframes, css } from 'styled-components'

import { colors, device, fontSize, media } from '../../theme'
import IconButton from '../../controls/IconButton'
import Button from '../../controls/Button'
import Icons from '../../assets/Icons'
import { formatFileSize } from '../../utils/helper'
import { fileDownload } from '../../service/document/getDownloadUrl'
import ProgressBar from '../../controls/ProgressBar'

const BadgeContainer = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;
`
const BadgeIconContainer = styled.div`
  position: relative;
  display: inline-block;
`
const Badge = styled.span`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  top: -5px;
  right: -3px;
  font-size: 9px;
  width: 17px;
  height: 17px;
  max-width: 100%;
  max-height: 100%;
  background: ${colors.secondary};
  color: ${colors.bgLight};
  border-radius: 50%;
`
const shake = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
`
const CustomIcon = styled(Icons)`
  position: relative;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  ${(props) =>
    props.$isShaking &&
    css`
      animation: ${shake} 0.5s infinite;
    `}
`
const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
`
const NotificationDropdownContainer = styled.div`
  position: relative;
`
const NotificationDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: calc(100% - 100px);
  z-index: 100;
  opacity: 0; /* Initial state for animation */
  animation: ${slideDown} 0.3s forwards;
  &.hide {
    animation: ${slideUp} 0.3s forwards;
  }
`

const NotificationList = styled.ul`
  background-color: ${colors.bgLighter};
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 200px;
  max-height: 400px;
  height: 100%;
  overflow-y: auto;

  /* WebKit browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.bgLighter};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary};
    border-radius: 4px;
    border: 3px solid ${colors.bgLighter};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primaryDark};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.secondary} ${colors.bgLighter};
`

const NotificationItem = styled.li`
  list-style: none;
  font-size: ${fontSize.sm};
  line-height: ${fontSize.xl};
  color: ${colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  padding: 5px;
  border: 1px solid rgb(243, 244, 246, 1);
  ${media.down(device.mobile)`
    
      min-width: 265px;
    `}
`
const NotificationDownloadIcon = styled(Icons)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 10px;
`

const NotificationTitle = styled.div`
  font-size: ${fontSize.normal};
  color: ${colors.secondary};
  margin-bottom: 4px;
  cursor: pointer;
`
const NotificationItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const NotificationSubTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  span {
    font-size: ${fontSize.xs};
    color: ${colors.grayDark};
  }
`

const Notification = () => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isDownloading, setIsDownloading] = useState()
  const [isShaking, setIsShaking] = useState(false)

  const notificationList = useSelector((state) => state.notification)
  const notification = notificationList.filter((e) => e?.isNotification)
  const notificationLength = useMemo(() => notification?.length, [notification])
  useEffect(() => {
    if (notificationLength) {
      setIsShaking(true)
      const timer = setTimeout(() => {
        setIsShaking(false)
      }, 500) // Duration of the shake animation

      // Cleanup timer
      return () => clearTimeout(timer)
    }
  }, [notificationLength])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.add('hide')
        setTimeout(() => {
          setIsOpen(false)
        }, 300)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const downloadFile = async (item) => {
    const file = {
      name: item.name,
      docBlobRef: item.docBlobRef,
      storageType: 'cdoc',
    }
    setIsDownloading(item.docId)
    await fileDownload([file])
    setIsDownloading()
  }
  const toggleNotification = () => {
    if (isOpen) {
      dropdownRef.current.classList.add('hide')
      setTimeout(() => {
        setIsOpen(false)
      }, 300) // Match the duration of the slideUp animation
    } else {
      setIsOpen(true)
    }
  }
  // const notificationList = [{
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // },
  // {
  //   title: "new_test_notification.pdf",
  //   size: 45,
  //   time: "just now"
  // }];
  function formatTimeStatus(modDate) {
    const now = new Date()
    const date = new Date(modDate)
    const diffInMilliseconds = now - date
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000)
    const diffInHours = Math.floor(diffInMilliseconds / 3600000)

    if (diffInMinutes < 1) {
      return 'now'
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`
    } else if (diffInHours < 24) {
      return `${diffInHours} hr${diffInHours !== 1 ? 's' : ''} ago`
    } else {
      return date.toLocaleTimeString()
    }
  }
  return (
    <NotificationDropdownContainer>
      <BadgeContainer onClick={toggleNotification}>
        <BadgeIconContainer>
          {notification?.length ? <Badge>{notification?.length}</Badge> : ''}
          <CustomIcon $isShaking={isShaking} toolName={'bell'} />
        </BadgeIconContainer>
      </BadgeContainer>

      {isOpen && (
        <NotificationDropdown ref={dropdownRef}>
          <NotificationList>
            {notification.length === 0 ? (
              <NotificationItem>No notifications</NotificationItem>
            ) : (
              notification.reverse().map(
                (e, i) =>
                  e?.isNotification && (
                    <NotificationItem key={e?.docId + '_' + i}>
                      <NotificationItemContent>
                        <NotificationTitle>{e?.name}</NotificationTitle>
                        {e?.docId && e?.docStatus !== 'finished' && (
                          <ProgressBar progress={e.progress} />
                        )}
                        <NotificationSubTitle>
                          <span>
                            {formatFileSize(e?.docMetadata?.size || e?.size)}
                          </span>
                          {e?.modifiedAt && (
                            <span>{formatTimeStatus(e?.modifiedAt)}</span>
                          )}
                        </NotificationSubTitle>
                      </NotificationItemContent>

                      {/* <NotificationDownloadIcon toolName={'download'} /> */}
                      {e.docId && (
                        <IconButton
                          icon="download"
                          onClick={() => downloadFile(e)}
                          loading={e.docId === isDownloading}
                        />
                      )}
                      {e.isOneDrive && (
                        <Icons
                          className="notification__success_icon"
                          toolName={'chromeSuccess'}
                        />
                      )}
                    </NotificationItem>
                  )
              )
            )}
          </NotificationList>
        </NotificationDropdown>
      )}
    </NotificationDropdownContainer>
  )
}

export default Notification
