import { useContext } from 'react'
import styled from 'styled-components'

import { media, device, deviceHeight } from '../../../../theme/viewer'
import IconButton from '../../../../controls/IconButton'
import PdfViewerContext from '../../pdfViewer/PdfViewerContext'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  background-color: #ffffff;
  margin-right: 2px;
  > button {
    height: 50px;
  }
  ${media.down(device.mobile)`
    flex-wrap: wrap;
    padding: 0;
    flex-direction: row;
  `}
  @media (max-height: ${deviceHeight.SMALL}) {
    padding: 1rem 0;
    > button {
      max-height: 35px;
      max-width: 35px;
      i {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
`

const AnnotationBar = ({ children }) => {
  const [state, actions] = useContext(PdfViewerContext)

  if (!state.modules || state.modules.length < 1) {
    return null
  }
  // if (!state.showAnnotationbar) {
  //   return null
  // }
  const translate = (translateKey) => {
    switch (translateKey) {
      case 'annotText.add':
        return 'Notes or Comments'
      case 'annotHighlight.add':
        return 'Highlighter'
      case 'annotFreeText.add':
        return 'Text Box'
      case 'annotFreeDrawing.add':
        return 'Draw or Paint'
      case 'annotStamp.add':
        return 'Stamp or Watermark'
      case 'annotShape.add':
        return 'Shapes'
      case 'annotImage.add':
        return 'Insert Images'
      case 'annotQrCode.add':
        return 'Generate and Add QR Codes'

      default:
        return translateKey
    }
  }
  return (
    <Wrapper>
      {state.modules
        .filter((m) => !m.moduleInfo.allowInViewOnly)
        .map((m) => {
          return (
            <IconButton
              key={m.moduleInfo.name}
              icon={m.moduleInfo.icon}
              onClick={() => {
                actions.activateModule(m.moduleInfo.name)
              }}
              tooltip={translate(m.moduleInfo.translationKey)}
            />
          )
        })}
    </Wrapper>
  )
}

export default AnnotationBar
