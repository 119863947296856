import config from '../../lib/config'
//import { logError } from '../../services/logger/logGlobalErrors'
import mutations from './mutations'
//import docProcessing from '../docProcessing/actions'
import thumbnails from '../thumbnails/actions'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { createNotification } from '../notification/action'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  removeJobId,
  setClosed,
  setClosing,
  setConnected,
  setConnecting,
  setError,
  setJobId,
} from './reducer'

const options = {}

const logError = () => {}

function createHub(store, reConnect) {
  const connection = new HubConnectionBuilder()
    .withUrl(config.SIGNALR_HUB_URL, options)
    .build()

  let thumbnailsQueue = []
  let thumbnailsQueueTimer

  const pushThumbnailToQueue = (doc) => {
    thumbnailsQueue.push(doc)
    if (!thumbnailsQueueTimer) {
      thumbnailsQueueTimer = setTimeout(() => {
        store.dispatch(thumbnails.pushThumbnails([...thumbnailsQueue]))
        thumbnailsQueue = []
        thumbnailsQueueTimer = undefined
      }, 200)
    }
  }

  connection.onclose(async (error) => {
    if (error) {
      store.dispatch(setClosed(error))

      const { hub } = store.getState()
      const { connectionId } = hub

      logError(error, {
        message: 'signalR connection closed, try to reconnect',
        connectionId: connectionId || 'no connectionId',
      })

      await reConnect(connectionId)
    }
  })

  // upload zip
  connection.on('DocUnzipMessage', () => {})

  // upload storage files
  connection.on('addStorageFileStatusChange', (data) => {
    //store.dispatch(docProcessing.addStorageFileStarted(data.jobId, data.docs))
  })
  connection.on('addStorageFileProgress', (data) => {
    //store.dispatch(docProcessing.addStorageFileProgress(data.jobId, data.docs))
  })
  connection.on('addStorageFileError', (data) => {
    //store.dispatch(docProcessing.addStorageFileFailed(data.jobId, data.docs))
  })

  // doc processing
  connection.on('docStatusChange', async (data) => {
    // store.dispatch(
    //   docProcessing.processingDocStatusChanged({
    //     ...data,
    //     size: data.fileSize,
    //     status: data.docStatus,
    //   })
    // )

    if (data.docStatus === 'inProgress') {
      store.dispatch(createNotification(data))
      await store.dispatch(setJobId(data.docId))
    }
    if (data.docStatus === 'finished') {
      store.dispatch(createNotification(data))
      store.dispatch(removeJobId(data.docId))
      const state = store.getState()
      if (!state.hub.docIds.length) store.dispatch(stopHub())
    }
  })

  // thumbnails
  connection.on('onlineImageStatusChange', (data) => {
    store.dispatch(thumbnails.progress(data))
  })
  connection.on('onlineImageThumbnail', (data) => {
    pushThumbnailToQueue(data)
  })
  connection.on('onlineImageCompleted', (data) => {
    store.dispatch(thumbnails.completed(data))
  })

  return connection
}
export const reConnect = createAsyncThunk(
  'hub/reConnect',
  async (connectionId, { dispatch, rejectWithValue, getState }) => {
    try {
      // Initialize the hub with store and reconnection logic
      const connection = createHub({ dispatch, getState }, reConnect) // Add this line here

      await connection.start()
      await connection.invoke('ReConnect', connectionId)

      return { connection, connectionId }
    } catch (error) {
      return rejectWithValue('Failed to reconnect to SignalR: ' + error.message)
    }
  }
)
export const startHub = createAsyncThunk(
  'hub/start',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      // Initialize the hub with store and reconnection logic
      const connection = createHub({ dispatch, getState }, reConnect) // Add this line here

      await connection.start()
      const connectionId = await connection.invoke('CreateChannel')

      // Other event listeners and error handlers
      const handleError = (error) => {
        dispatch(setError(error))
      }

      connection.onclose(async (error) => {
        if (error) {
          dispatch(setClosed(error))
          const { connectionId } = getState().hub
          await reConnect(connectionId)
        }
      })

      return { connection, connectionId }
    } catch (error) {
      return rejectWithValue(
        'Failed to start SignalR connection: ' + error.message
      )
    }
  }
)

export const stopHub = createAsyncThunk(
  'hub/stop',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { hub } = getState()
      if (hub.connection) {
        await hub.connection.stop()
      }
    } catch (error) {
      return rejectWithValue(
        'Failed to stop SignalR connection: ' + error.message
      )
    }
  }
)
export function hubMiddleware(store) {
  return (next) => async (action) => {
    switch (action?.type) {
      case 'HUB_START':
        await store.dispatch(startHub())
        break

      case 'HUB_STOP':
        await store.dispatch(stopHub())
        break

      // case 'INITIALIZE_APP_COMPLETED':
      //   start()
      //   break

      default:
        break
    }
    return next(action)
  }
}
