import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { uploadFile } from '../../../service/oneDrive/uploadFile'
import { createFolder } from '../../../service/oneDrive/createFolder'
import { fileUpload } from '../../../service/document/fileUpload'
import { errorHandler, successHandler } from '../../error/action'
import { createId } from '../../../utils/createId'
import { oneDriveNotification } from '../../notification/action'
import { app } from '@microsoft/teams-js'
import { fetchToken } from '../../../utils/helper'
import { getListItems } from '../../../service/oneDrive/oneDriveFiles'
import { updateReadWriteField } from '../../teamsAuth/teamsAuthSlice'
import { breadCrumbNavigate } from '../Slice/oneDrivePickerSlice'

export const getOneDriveFileItems = createAsyncThunk(
  'oneDrive/getFileItems',
  async (
    { teamsUserCredential, itemId, driveId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const token = await fetchToken(teamsUserCredential)
      const teamsContext = await app.getContext()
      const response = await getListItems(
        token,
        itemId,
        driveId,
        teamsContext.user.tenant.id
      ) // Make sure to await the response
      if (response.errorMessage) throw new Error(response.errorMessage)
      return response
    } catch (error) {
      // error?.message &&
      //   !error.message.includes('Azure.Iden') &&
      //   dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)
export const uploadFileToOneDrive = createAsyncThunk(
  'oneDrive/uploadFile',
  async (
    { teamsUserCredential, name, itemPath, file },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { oneDrive, hub } = getState()
      const { connectionId } = hub
      const { items, driveId, history, itemId } = oneDrive

      const teamsContext = await app.getContext()
      const token = await fetchToken(teamsUserCredential)

      const onProgress = (progress) => {
        return {}
      }
      const data = await fileUpload(
        'cdoc',
        file,
        createId(),
        'oneDrive',
        connectionId,
        onProgress
      )
      const response = await uploadFile(
        name,
        itemPath,
        driveId || items[0]?.driveId,
        data[0].docBlobRef,
        token,
        teamsContext.user.tenant.id
      )
      const index = history.length ? history.length - 1 : 0
      await dispatch(
        breadCrumbNavigate(teamsUserCredential, driveId, itemId, index)
      )
      dispatch(
        oneDriveNotification({
          name: file.name,
          size: file.size,
          itemId: response.itemId,
          isNotification: true,
          isOneDrive: true,
        })
      )
      dispatch(successHandler('File uploaded successfully'))
      return response
    } catch (error) {
      dispatch(updateReadWriteField({ isReadWrite: false }))
      dispatch(errorHandler(error))

      return rejectWithValue(error.message)
    }
  }
)
export const createFolderInOneDrive = createAsyncThunk(
  'oneDrive/createFolder',
  async (
    { teamsUserCredential, folderName, absolutePath },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { oneDrive, hub } = getState()
      // const { connectionId } = hub
      const { items, driveId, history, itemId } = oneDrive
      const teamsContext = await app.getContext()
      const token = await fetchToken(teamsUserCredential)

      const lastHistory = history[history.length ? history.length - 1 : 0]
      const response = await createFolder(
        folderName,
        absolutePath,
        lastHistory?.driveId,
        lastHistory?.itemId,
        token,
        teamsContext.user.tenant.id
      )
      const index = history.length ? history.length - 1 : 0
      await dispatch(
        breadCrumbNavigate(teamsUserCredential, driveId, itemId, index)
      )
      dispatch(successHandler('Folder created Successfully'))
      return true
    } catch (error) {
      dispatch(errorHandler('Failed to Create Folder'))
      rejectWithValue(error.message)
      return false
    }
  }
)
