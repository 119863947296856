import { getListItems } from '../../../service/oneDrive/oneDriveFiles'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { errorHandler } from '../../error/action'
import { app } from '@microsoft/teams-js'
import {
  updateReadWriteField,
  updateUserInfo,
} from '../../teamsAuth/teamsAuthSlice'
import {
  getOneDriveFileItems,
  uploadFileToOneDrive,
  createFolderInOneDrive,
} from '../Actions/oneDriveFileActions'
import { LoadingStates } from '../../../utils/enums'

// Define JSDoc annotations for the state
/**
 * @typedef {Object} OneDriveState
 * @property {Array<Object>} items - List of items
 * @property {'idle' | 'pending' | 'fulfilled' | 'rejected'} isLoading - Loading state
 * @property {string | null} error - Error message
 * @property {string} driveId - Drive ID
 * @property {string} itemId - Item ID
 * @property {Array<{ driveId: string, itemId: string, name: string }>} history - Navigation history
 */

/** @type {OneDriveState} */
const initialState = {
  items: [],
  isLoading: LoadingStates.IDLE,
  error: null,
  driveId: '', // Default drive ID, change if needed
  itemId: '', // Default item ID, change if needed
  history: [],
}
const oneDriveSlice = createSlice({
  name: 'onedrive',
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.driveId = action.payload.driveId
      state.itemId = action.payload.itemId
    },
    addToHistory: (state, action) => {
      state.history.push(action.payload)
    },
    goBack: (state, action) => {
      const index = action.payload.index
      const preHistory = state.history.slice(0, index + 1)
      if (preHistory) {
        state.driveId = action.payload.driveId
        state.itemId = action.payload.itemId
        state.history = [...preHistory]
      }
    },
    resetDrive: (state) => {
      state.items = initialState.items
      state.isLoading = initialState.isLoading
      state.error = initialState.error
      state.driveId = initialState.driveId
      state.itemId = initialState.itemId
      state.history = initialState.history
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneDriveFileItems.pending, (state) => {
        state.isLoading = LoadingStates.PENDING
        // state.error = null
      })
      .addCase(getOneDriveFileItems.fulfilled, (state, action) => {
        state.isLoading = LoadingStates.FULFILLED
        state.items = action.payload.items || []
      })
      .addCase(getOneDriveFileItems.rejected, (state, action) => {
        state.isLoading = LoadingStates.REJECTED
        // state.error = action.payload
      })
      .addCase(uploadFileToOneDrive.pending, (state) => {
        state.isLoading = LoadingStates.PENDING
      })
      .addCase(uploadFileToOneDrive.fulfilled, (state) => {
        state.isLoading = LoadingStates.FULFILLED
      })
      .addCase(uploadFileToOneDrive.rejected, (state) => {
        state.isLoading = LoadingStates.REJECTED
      })
      .addCase(createFolderInOneDrive.pending, (state) => {
        state.isLoading = LoadingStates.PENDING
      })
      .addCase(createFolderInOneDrive.fulfilled, (state) => {
        state.isLoading = LoadingStates.FULFILLED
      })
      .addCase(createFolderInOneDrive.rejected, (state) => {
        state.isLoading = LoadingStates.REJECTED
      })
  },
})

export const { setPath, addToHistory, goBack, resetDrive } =
  oneDriveSlice.actions

export const fetchItemsForPath =
  (teamsUserCredential, driveId, itemId, name) => async (dispatch) => {
    dispatch(addToHistory({ driveId, itemId, name }))
    dispatch(setPath({ driveId, itemId }))
    const res = await dispatch(
      getOneDriveFileItems({ teamsUserCredential, driveId, itemId })
    )
    if (getOneDriveFileItems.rejected.match(res)) {
      // await dispatch(updateReadWriteField({ isReadFiles: false }))
      // // res.payload &&
      // //   !res.payload.includes('Azure.Iden') &&
      // dispatch(
      //   errorHandler({
      //     message:
      //       'Your permissions are not yet updated. Please check your permissions and try again.',
      //   })
      // )
    }
  }
export const breadCrumbNavigate =
  (teamsUserCredential, driveId, itemId, index) => async (dispatch) => {
    dispatch(
      goBack({
        driveId,
        itemId,
        index,
      })
    )
    const res = await dispatch(
      getOneDriveFileItems({ teamsUserCredential, driveId, itemId })
    )
    if (getOneDriveFileItems.rejected.match(res)) {
      // await dispatch(updateReadWriteField({ isReadFiles: false }))
      // // res.payload &&
      // //   !res.payload.includes('Azure.Iden') &&
      // dispatch(
      //   errorHandler({
      //     message:
      //       'Your permissions are not yet updated. Please check your permissions and try again.',
      //   })
      // )
    }
  }

export default oneDriveSlice.reducer
