import styled, { keyframes } from 'styled-components'

import { colors, layout, fontSize, media, device } from '../../theme'

const flipIn = keyframes`
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
`

export const FileList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.down(device.mobile)`
    margin-left: -16px;
    margin-right: -16px;
  `}
  li {
    padding: 4px 0;
    margin: 0;
    animation: ${flipIn} 0.25s ease-in;
    ${media.down(device.mobile)`
      padding: 0;
    `}
  }
`

export const File = styled.div`
  display: flex;
  padding: 0;
  height: 98px;
  position: relative;
  user-select: none;
  background-color: #fff;
  border: solid 1px var(--bg-light);
  border-radius: 4px;
  overflow: hidden;

  ${media.down(device.mobile)`
    border-radius: 0;
    border-left: none;
    border-right: none;
    margin-bottom: -1px;
  `}

  i.drag-icon {
    margin-left: auto;
    width: 18px;
    svg {
      width: 18px;
    }
  }
  &.error {
    p {
      color: ${colors.error};
    }
    svg {
      fill: ${colors.error};
    }
    button {
      i {
        svg {
          fill: ${colors.gray};
        }
      }
    }
  }
`

export const FileDesc = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: calc(100% - 96px);
  max-width: calc(100% - 96px);
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span:not(:first-child) {
    &:before {
      content: '|';
      padding: 0 8px;
      color: var(--text-muted);
    }
  }
`

export const FileProps = styled.div`
  display: flex;
  color: var(--text-muted);
  font-size: ${fontSize.sm};
  ${media.down(device.mobile)`
    display: none;
  `}
`

export const FileActions = styled.div`
  .btn {
    margin-right: 8px;
  }
  ${media.up(device.mobile)`
    .btn {
      margin-right: 0;
      margin-left: 8px;
    }
    margin-left: auto;
  `}
`

export const FileName = styled.div`
  margin-bottom: auto;
  max-width: 100%;
  min-height: 32px;
  > div {
    max-width: 100%;
    font-size: ${fontSize.lg};
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ProgressBar = styled.div`
  position: absolute;
  bottom: 2px;
  left: 100px;
  right: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #afbfe1;

  div {
    height: 4px;
    border-radius: 3px;
    background-color: ${colors.secondary};
  }
`
