import styled from 'styled-components'

import { layout, media, device, colors, fontSize } from '../../theme/viewer'
import Icon from '../Icon common'

const Button = styled.button`
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  padding: 0 10px;
  cursor: pointer;
  i {
    display: inline-block;
    margin: 8px 0;
  }
  .label {
    display: block;
    margin-left: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    white-space: nowrap;

    ${media.down(device.mobile)`
      display: none;
    `}
  }
  svg {
    width: 26px;
    height: 20px;
  }

  &.disabled {
    span {
      color: ${colors.gray};
    }
    svg {
      fill: ${colors.gray};
    }
  }

  ${media.down(device.tablet)`
    min-width: 45px;
  `}

  ${media.down(device.mobile)`
    width: 37px;
  `}
`

const IconButton = ({
  icon,
  disabled,
  onClick,
  className,
  tooltip,
  text,
  loading,
  iconClassName = '',
}) => {
  const classnames = []

  if (disabled) {
    classnames.push('disabled')
  }

  if (className) {
    classnames.push(className)
  }

  return (
    <Button
      className={classnames.join(' ')}
      onClick={onClick}
      type="button"
      disabled={disabled}
      title={tooltip}
    >
      {loading ? (
        <div className="btnLoader" />
      ) : (
        <Icon className={iconClassName} icon={icon} />
      )}
      {text && <span className="label">{text}</span>}
    </Button>
  )
}

export default IconButton
