import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { getTeamsUserConsent } from '../../service/userInfo/getTeamsUserConsent'
import { browserHistory } from '../../history'
export const updateReadWriteField = createAsyncThunk(
  'teams/auth/updateReadWrite',
  async (consent, { rejectWithValue, getState, dispatch }) => {
    const { teamsAuth } = getState()
    const { preferredUserName } = teamsAuth
    try {
      let res
      if (consent?.isReadWrite) {
        res = await getTeamsUserConsent(preferredUserName, consent.isReadWrite)
        dispatch(updateUserInfo({ isReadFiles: true }))
      } else if (consent?.isReadWrite === false) {
        res = await getTeamsUserConsent(preferredUserName, consent.isReadWrite)
        dispatch(updateUserInfo({ isReadFiles: false }))
      } else {
        res =
          preferredUserName && (await getTeamsUserConsent(preferredUserName))
      }

      return res
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)
const teamsAuthSlice = createSlice({
  name: 'teams/auth',
  initialState: {
    preferredUserName: null,
    isReadFiles: false,
    isReadWrite: false,
  },
  reducers: {
    updateUserInfo: (state, action) => {
      if (action.payload?.preferredUserName)
        state.preferredUserName = action.payload.preferredUserName
      if (action.payload?.isReadFiles)
        state.isReadFiles = action.payload.isReadFiles
      if (action.payload?.isReadWrite)
        state.isReadWrite = action.payload.isReadWrite
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReadWriteField.fulfilled, (state, action) => {
        state.isReadWrite = action.payload?.consent || false
      })
      .addCase(updateReadWriteField.rejected, (state, action) => {
        console.error('Failed to update readWrite field:', action.payload)
      })
  },
})

export const { updateUserInfo } = teamsAuthSlice.actions
export default teamsAuthSlice.reducer
