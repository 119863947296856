import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 78">
        <path
          fill="currentColor"
          d="M34.263 77.416c-5.887-1.836-9.162-7.689-9.175-16.367 0-2.773.156-4.102.714-5.892 1.356-4.376 4.955-7.67 9.687-8.89 2.355-.603 3.08-1.248 3.08-2.757 0-.47.282-1.875.627-3.12 1.556-5.658 4.439-10.366 7.512-12.288 3.22-2.018 4.85-2.468 8.744-2.445 5.538.037 8.295 1.539 12.156 6.607l2.126 2.791 1.899-.82c9.207-3.985 18.388 2.795 19.132 14.13l.201 3.101 1.812.812c5.18 2.321 7.613 7.189 7.174 14.351-.288 4.681-2.04 8.42-4.811 10.273l-1.307.874-28.987.072c-22.275.055-29.356-.049-30.582-.432h-.002Zm-24.181-5.179c-3.532-1.111-7.277-5.236-9.032-9.944C.055 59.618 0 59.22 0 54.492c0-4.5.087-5.218.873-7.454 1.668-4.708 4.876-8.106 8.886-9.45.85-.28 1.644-.734 1.77-1.002.125-.265.27-1.726.312-3.241.28-9.424 4.962-17.726 11.495-20.393 3.53-1.437 8.161-1.576 12 .452 1.218.642 1.081.782 3.656-3.68 1.526-2.638 4.407-5.43 6.926-7.1C48.638.821 51.463-.009 54.843 0c9.452.032 17.594 7.836 20.606 19.756.962 3.806.912 4.877-.22 4.91-.494.013-1.913.373-3.155.795l-2.257.775-2.054-2.72c-5.8-7.656-15.257-9.313-23.301-4.078-3.213 2.094-5.795 5.093-7.744 9.008-1.388 2.791-3.163 8-3.163 9.29 0 .916-.563 1.377-2.962 2.415C23.16 43.37 18.824 50.81 18.83 60.325c0 3.46.676 7.705 1.613 10.124.357.916.55 1.782.438 1.931-.288.386-9.532.252-10.813-.147l.013.004Z"
        />
      </svg>
    </div>
  )
}
