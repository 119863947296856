import { createContext, useContext, useEffect, useState } from 'react'

import { device } from '../theme'

export const ViewportContext = createContext()

export const useViewport = () => useContext(ViewportContext)

export const ViewportProvider = (props) => {
  const { children } = props
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const values = {
    mobile: width <= device.mobile,
    tablet: width > device.mobile && width <= device.tablet,
    labtop: width > device.tablet && width <= device.labtop,
    desktop: width >= device.desktop,
    width,
    height
  }

  return (
    <ViewportContext.Provider value={values}>
      {children}
    </ViewportContext.Provider>
  )
}
