import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 17">
        <path
          fill="currentColor"
          d="M15 17c-1.114 0-2.059-.388-2.835-1.164C11.388 15.059 11 14.113 11 13c0-1.113.388-2.058 1.165-2.835C12.942 9.388 13.887 9 15 9s2.059.388 2.836 1.165c.777.777 1.165 1.722 1.164 2.835-.001 1.113-.39 2.059-1.164 2.836-.775.777-1.72 1.165-2.836 1.164Zm1.65-1.804.546-.546-1.811-1.811v-2.723h-.77v3.046l2.035 2.034ZM10.77 4.04H17v3.28c.187.053.36.116.521.191.16.075.32.164.479.265v-6.16c0-.46-.154-.844-.462-1.152A1.571 1.571 0 0 0 16.385 0H1.615C1.155 0 .771.154.463.463A1.57 1.57 0 0 0 0 1.616v10.769c0 .46.154.844.463 1.153A1.56 1.56 0 0 0 1.615 14h7.474a3.243 3.243 0 0 1-.072-.494A7.459 7.459 0 0 1 9 13H1.616a.591.591 0 0 1-.424-.192.584.584 0 0 1-.192-.424V1.616c0-.154.064-.295.192-.424A.583.583 0 0 1 1.615 1h9.154l.001 3.04ZM1 12.384V13 1v11.384Z"
        />
      </svg>
    </div>
  )
}
