/* eslint-disable import/no-anonymous-default-export */
import { MessageType } from '../../utils/enums'
export default (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_ERROR': {
      let { message, code, name, className, data, errors } = action.data
      if (message === 'Socket connection timed out') {
        message = 'Sorry, failed to connect server'
      }
      if (name === 'Timeout') {
        message = 'Sorry, server request timed out'
      }
      return {
        message,
        code,
        name,
        className,
        data: { ...data },
        errors: { ...errors },
        type: MessageType.Error,
      }
    }

    case 'SHOW_SUCCESS': {
      let { message } = action.data
      return { message, type: MessageType.Success }
    }
    case 'REMOVE_MESSAGE':
      return {
        ...action.data,
      }
    case 'OTP_VERIFY':
      return {
        ...action.data,
      }
    default:
      return state
  }
}
